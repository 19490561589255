import { TokenService } from '@/api/new/services/storage.service'
import { refreshAccessToken } from '@/api/new/services/tokenRefresh.service'
import i18n from '@/i18n'
import store from '@/store/store'
import axios from 'axios'

const ApiServiceV2 = {
  cachedRequests: [],
  init(baseURL) {
    axios.defaults.baseURL = baseURL
    axios.defaults.withCredentials = true
    axios.interceptors.request.use(
      async (req) => {
        const refreshToken = TokenService.getRefreshToken()
        if (!refreshToken && !req.isPublicRequest) return
        return req
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error && error.response.status === 401) {
          const req = error.config
          const refreshToken = TokenService.getRefreshToken()
          if (refreshToken && !req.isPublicRequest) {
            await refreshAccessToken(req, refreshToken)
          }
          return axios(req)
        } else if (
          error &&
          error.response.status === 403 &&
          Object.keys(error.response.data.errors).includes('otp')
        ) {
          store.dispatch(
            'modals/triggerMfaVerificationModal',
            {},
            { root: true }
          )
        } else if (
          error &&
          error.response.status === 422 &&
          error.response.data.message ===
            'The terms and conditions field is required.'
        ) {
          store.dispatch(
            'modals/triggerCooperationAgreementModal',
            {},
            { root: true }
          )
        } else if (error.response.data.localization) {
          const message =
            error.response.data.localization.key &&
            i18n.t(
              error.response.data.localization.key,
              error.response.data.localization.variables
            ) !== error.response.data.localization.key
              ? i18n.t(
                  error.response.data.localization.key,
                  error.response.data.localization.variables
                )
              : error.response.data.data.message
          store.dispatch('toast/error', { message: message }, { root: true })
          return Promise.reject(error)
        } else {
          store.dispatch(
            'toast/error',
            {
              title: i18n.t('shared.toast.error.title'),
              message: error.response.data.message
            },
            { root: true }
          )
          return Promise.reject(error)
        }
      }
    )
  },
  get(resource, params) {
    if (params) {
      return axios.get(`laas/api/v2/${resource}`, params)
    } else {
      return axios.get(`laas/api/v2/${resource}`)
    }
  },
  post(resource, data) {
    if (!this.cachedRequests.includes(resource)) {
      this.cachedRequests.push(resource)
      setTimeout(() => {
        this.cachedRequests = []
      }, 500)
      return axios.post(`laas/api/v2/${resource}`, data)
    } else {
      this.cachedRequests = []
      return Promise.reject({
        response: {
          data: {
            message: 'Duplicated request was stopped'
          }
        }
      })
    }
  },
  postFormData(resource, data) {
    if (!this.cachedRequests.includes(resource)) {
      this.cachedRequests.push(resource)
      setTimeout(() => {
        this.cachedRequests = []
      }, 500)
      return axios.post(`laas/api/v2/${resource}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } else {
      this.cachedRequests = []
      return Promise.reject({
        response: {
          data: {
            message: 'Duplicated request was stopped'
          }
        }
      })
    }
  },
  put(resource, data) {
    return axios.put(`laas/api/v2/${resource}`, data)
  },
  patch(resource, data) {
    return axios.patch(`laas/api/v2/${resource}`, data)
  },
  delete(resource) {
    return axios.delete(`laas/api/v2/${resource}`)
  },
  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data)
  }
}

export default ApiServiceV2
