import axios from 'axios'

const ApiConnectorsService = {
  cachedRequests: [],
  init(baseURL) {
    axios.defaults.baseURL = baseURL
    axios.defaults.withCredentials = true
  },
  get(resource, params) {
    if (params) {
      return axios.get(`connectors/api/v1/${resource}`, params)
    } else {
      return axios.get(`connectors/api/v1/${resource}`)
    }
  },
  post(resource, data) {
    if (
      !this.cachedRequests.includes(resource) ||
      resource.includes('content-pages')
    ) {
      this.cachedRequests.push(resource)
      setTimeout(() => {
        this.cachedRequests = []
      }, 500)
      return axios.post(`connectors/api/v1/${resource}`, data)
    } else {
      this.cachedRequests = []
      return Promise.reject({
        response: {
          data: {
            message: 'Duplicated request was stopped'
          }
        }
      })
    }
  }
}

export default ApiConnectorsService
