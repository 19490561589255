import i18n from '@/i18n'

const ProjectService = {
  getService(flow) {
    switch (flow) {
      case 'translation+review':
        return i18n.t('shared.label.translation_review')
      case 'self+machine_translation':
        return i18n.t('shared.label.translate_yourself')
      default:
        return i18n.t('shared.label.translation')
    }
  },

  getStatus(status) {
    let state = {
      COMPLETED: {
        progressStarted: false,
        text: i18n.t('shared.status.completed'),
        theme: 'green',
        colors: {
          primary: '#29C1A7',
          secondary: '#E3F9F2'
        }
      },
      CANCELLED_BY_CUSTOMER: {
        progressStarted: false,
        text: i18n.t('shared.status.project_cancelled'),
        theme: 'red',
        colors: {
          primary: '#FF0A0A',
          secondary: '#FFF2F2'
        }
      },
      PRICE_DECLINED: {
        progressStarted: false,
        text: i18n.t('shared.status.project_declined'),
        theme: 'gray',
        colors: {
          primary: '#496684',
          secondary: '#F6F7F9'
        }
      },
      APPROVAL_NEEDED: {
        progressStarted: false,
        text: i18n.t('shared.status.need_approval'),
        theme: 'yellow',
        colors: {
          primary: '#DCB621',
          secondary: '#FFF8DC'
        }
      },
      ASSIGNED: {
        progressStarted: true,
        text: i18n.t('shared.status.in_progress'),
        theme: 'blue',
        colors: {
          primary: '#2c3fd5',
          secondary: '#f3f6fa'
        }
      },
      PRICE_ACCEPTED: {
        progressStarted: true,
        text: i18n.t('shared.status.in_progress'),
        theme: 'blue',
        colors: {
          primary: '#2c3fd5',
          secondary: '#f3f6fa'
        }
      },
      CREATED: {
        progressStarted: true,
        text: i18n.t('shared.status.processing'),
        theme: 'blue',
        colors: {
          primary: '#2c3fd5',
          secondary: '#f3f6fa'
        }
      }
    }
    return state[status]
  }
}

export { ProjectService }
