import { $cookies } from '@/main'

const REFRESH_TOKEN_KEY = process.env.VUE_APP_REFRESH_TOKEN_COOKIE
const OLD_REFRESH_TOKEN_KEY = process.env.VUE_APP_OLD_REFRESH_TOKEN_COOKIE
const DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN

const TokenService = {
  saveFigmaPat(pat) {
    $cookies.set('figma_pat', pat)
  },
  saveHygraphPat(pat) {
    $cookies.set('hygraph_pat', pat)
  },

  removeAll() {
    window.localStorage.clear()
    const refreshToken = $cookies.get(OLD_REFRESH_TOKEN_KEY)
    document.cookie = `${OLD_REFRESH_TOKEN_KEY}=${refreshToken}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; samesite=lax;`
    $cookies.remove(REFRESH_TOKEN_KEY, '/', `.${DOMAIN}`)
    $cookies.remove('team_identifier')
    $cookies.remove('userType')
    $cookies.remove('firstName')
    $cookies.remove('lastName')
    $cookies.remove('env')
    $cookies.remove('supplierId')
    $cookies.remove('superAdmin')
    $cookies.remove('isRegistered')
    $cookies.remove('invitedSubAccount')
    $cookies.remove('supplierOnboarding')
    $cookies.remove('showSupplierListView')
    $cookies.remove('loginCreatedUser')
  },

  getRefreshToken() {
    const refreshToken = $cookies.get(REFRESH_TOKEN_KEY)
    if (refreshToken) {
      return refreshToken
    } else {
      return $cookies.get(OLD_REFRESH_TOKEN_KEY)
    }
  },

  saveRefreshToken(refreshToken) {
    $cookies.set(REFRESH_TOKEN_KEY, refreshToken, null, '/', `.${DOMAIN}`)
  },

  removeRefreshToken() {
    $cookies.remove(REFRESH_TOKEN_KEY)
  }
}

export { TokenService }
