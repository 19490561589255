import axios from 'axios'

const ApiEasycatService = {
  cachedRequests: [],
  init(baseURL) {
    axios.defaults.baseURL = baseURL
    axios.defaults.withCredentials = true
  },
  get(resource, params) {
    if (params) {
      return axios.get(`easycat/api/v1/${resource}`, params)
    } else {
      return axios.get(`easycat/api/v1/${resource}`)
    }
  },
  post(resource, data) {
    if (
      !this.cachedRequests.includes(resource) ||
      resource.includes('content-pages')
    ) {
      this.cachedRequests.push(resource)
      setTimeout(() => {
        this.cachedRequests = []
      }, 500)
      return axios.post(`easycat/api/v1/${resource}`, data)
    } else {
      this.cachedRequests = []
      return Promise.reject({
        response: {
          data: {
            message: 'Duplicated request was stopped'
          }
        }
      })
    }
  },
  postFormData(resource, data) {
    if (!this.cachedRequests.includes(resource)) {
      this.cachedRequests.push(resource)
      setTimeout(() => {
        this.cachedRequests = []
      }, 500)
      return axios.post(`easycat/api/v1/${resource}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } else {
      this.cachedRequests = []
      return Promise.reject({
        response: {
          data: {
            message: 'Duplicated request was stopped'
          }
        }
      })
    }
  },
  put(resource, data) {
    return axios.put(`easycat/api/v1/${resource}`, data)
  },
  patch(resource, data) {
    return axios.patch(`easycat/api/v1/${resource}`, data)
  },
  delete(resource) {
    return axios.delete(`easycat/api/v1/${resource}`)
  }
}

export default ApiEasycatService
