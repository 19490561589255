import store from '@/store/store'

let isRefreshing = false
let requestsQueue = []

export async function refreshAccessToken(req, refreshToken) {
  return new Promise(async (resolve, reject) => {
    requestsQueue.push({ req, refreshToken, resolve, reject })
    if (!isRefreshing) {
      isRefreshing = true
      try {
        await store
          .dispatch('auth/refreshToken', { refreshToken }, { root: true })
          .then(() => {
            requestsQueue.forEach((queuedRequest) =>
              queuedRequest.resolve(queuedRequest.req)
            )
            requestsQueue = []
          })
      } finally {
        isRefreshing = false
      }
    }
  })
}
